import React, { useEffect } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

function monthSort(a, b) {
	const splitA = a.split("-");
	const splitB = b.split("-");
	const yearA = parseInt(splitA[0], 10);
	const monthA = parseInt(splitA[1], 10);
	const yearB = parseInt(splitB[0], 10);
	const monthB = parseInt(splitB[1], 10);

	if (yearA < yearB) return -1;
	if (yearA > yearB) return 1;
	return monthA - monthB;
}

function aggregateAndCompareData(measuredData, expectedData, asBuiltData, period = "month") {
	asBuiltData = asBuiltData ? asBuiltData : [];
	const aggregate = (data) => {
		const result = {};
		data.forEach((item) => {
			const date = new Date(item.Date);

			let key;
			switch (period) {
				case "day":
					key = date.toISOString().split("T")[0];
					break;
				case "week":
					let startOfWeekDate = new Date(date);
					startOfWeekDate.setUTCDate(startOfWeekDate.getUTCDate() - startOfWeekDate.getUTCDay());
					if (startOfWeekDate.getUTCMonth() !== date.getUTCMonth()) {
						startOfWeekDate.setUTCDate(1);
						startOfWeekDate.setUTCMonth(date.getUTCMonth());
					}
					key = startOfWeekDate.toISOString().split("T")[0];
					break;
				case "month":
				case "trailing13months":
					key = `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}`;
					break;
				case "year":
					key = `${date.getUTCFullYear()}`;
					break;
				default:
					key = item.date;
			}

			if (!result[key]) {
				result[key] = { kWh: 0 };
			}
			result[key].kWh += item.kWh;
		});
		return result;
	};

	const measuredAggregated = aggregate(measuredData);
	const expectedAggregated = aggregate(expectedData);
	const asBuiltAggregated = aggregate(asBuiltData);

	const combinedData = {};
	Object.keys(measuredAggregated).forEach((key) => {
		combinedData[key] = {
			measuredkWh: measuredAggregated[key].kWh,
			expectedkWh: expectedAggregated[key] ? expectedAggregated[key].kWh : 0,
			asBuiltkWh: asBuiltAggregated[key] ? asBuiltAggregated[key].kWh : 0,
		};
	});

	Object.keys(expectedAggregated).forEach((key) => {
		if (!combinedData[key]) {
			combinedData[key] = {
				measuredkWh: 0,
				expectedkWh: expectedAggregated[key] ? expectedAggregated[key].kWh : 0,
				asBuiltkWh: asBuiltAggregated[key] ? asBuiltAggregated[key].kWh : 0,
			};
		}
	});
	switch (period) {
		case "month":
			const years = Object.keys(combinedData).map((key) => key.split("-")[0]);
			const minYear = Math.min(...years);
			const maxYear = Math.max(...years);

			for (let year = minYear; year <= maxYear; year++) {
				for (let month = 1; month <= 12; month++) {
					const key = `${year}-${month}`;
					if (!combinedData[key]) {
						combinedData[key] = { measuredkWh: 0, expectedkWh: 0, asBuiltkWh: 0 };
					}
				}
			}
			return Object.keys(combinedData)
				.map((key) => ({
					period: key,
					measuredkWh: combinedData[key].measuredkWh,
					expectedkWh: combinedData[key].expectedkWh,
					asBuiltkWh: combinedData[key].asBuiltkWh,
				}))
				.sort((a, b) => monthSort(a.period, b.period));
		case "trailing13months":
			const allPeriods = Object.keys(combinedData);
			const newestPeriod = new Date(Math.max(...allPeriods.map((d) => new Date(d))));
			const last13Periods = [];

			for (let i = 0; i < 13; i++) {
				const month = newestPeriod.getMonth() - i;
				const year = newestPeriod.getFullYear();

				const adjustedYear = year + Math.floor(month / 12);
				const adjustedMonth = (((month % 12) + 12) % 12) + 1;

				const key = `${adjustedYear}-${adjustedMonth}`;
				last13Periods.push(key);
			}

			last13Periods.forEach((key) => {
				if (!combinedData[key]) {
					combinedData[key] = { measuredkWh: 0, expectedkWh: 0, asBuiltkWh: 0 };
				}
			});

			return last13Periods.reverse().map((key) => ({
				period: key,
				measuredkWh: combinedData[key].measuredkWh,
				expectedkWh: combinedData[key].expectedkWh,
				asBuiltkWh: combinedData[key].asBuiltkWh,
			}));
		default:
			break;
	}

	return Object.keys(combinedData)
		.map((key) => ({
			period: key,
			measuredkWh: combinedData[key].measuredkWh,
			expectedkWh: combinedData[key].expectedkWh,
			asBuiltkWh: combinedData[key].asBuiltkWh,
		}))
		.sort((a, b) => monthSort(a.period, b.period));
}

function MyChartCompare({ measuredData, expectedData, asBuiltData, period, selection, isPublicStorage = false }) {
	const comparisonData = aggregateAndCompareData(measuredData, expectedData, asBuiltData, period);
	return (
		<ResponsiveContainer width="100%" height={250}>
			<BarChart
				data={comparisonData}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="period" />
				<YAxis />
				<Tooltip formatter={(value) => `${Math.round(value)} kWh`} />
				<Legend />
				{selection.measured ? <Bar dataKey="measuredkWh" fill="#82ca9d" name="Measured kWh" cursor={"pointer"} /> : null}
				{selection.expected && isPublicStorage ? <Bar dataKey="expectedkWh" fill="#8884d8" name="UW Expected kWh" cursor={"pointer"} /> : null}
				{selection.asBuilt ? <Bar dataKey="asBuiltkWh" fill="#ffa07a" name="As Built kWh" cursor={"pointer"} /> : null}
			</BarChart>
		</ResponsiveContainer>
	);
}

export default MyChartCompare;
