import { VscGraphLine } from "react-icons/vsc";
import { Link } from "react-router-dom";
import AlertIconList from "../Alerts/AlertIconList";
import PercentBar from "./PercentBar";

const removeAutonums = (s) => {
	const regex = /(-\s?\d+)+$/;
	return s.replace(regex, "");
};

export function MonitoringRow({ site, bgColor, headersOrder, isLast }) {
	return (
		<>
			{headersOrder.map((mapData, index) => {
				if (mapData.id === "title") {
					return (
						<div
							key={mapData.id + "title"}
							className={`p-1 col-span-2 ${bgColor} ${isLast && index === 0 ? "rounded-bl-lg" : ""} ${
								isLast && index === headersOrder.length - 1 ? "rounded-br-lg" : ""
							} flex items-center justify-center laptop:justify-normal overflow-hidden`}>
							<div className="flex gap-2 p-1 items-center" title={site.name}>
								<Link to={"/monitoring/" + site.id}>
									<VscGraphLine color="blue" />
								</Link>
								<div>
									<p>{site.x_studio_customer_portal_alias ? site.x_studio_customer_portal_alias : removeAutonums(site.name)}</p>
									{site.x_studio_customer_portal_alias ? <p className="font-thin text-xs">{removeAutonums(site.name)}</p> : null}
								</div>
							</div>
						</div>
					);
				} else if (mapData.id === "alerts") {
					return (
						<div
							key={mapData.id + "alerts"}
							className={`p-1 col-span-2 laptop:col-span-1 ${bgColor} ${isLast && index === 0 ? "rounded-bl-lg" : ""} ${
								isLast && index === headersOrder.length - 1 ? "rounded-br-lg" : ""
							} flex items-center justify-center laptop:justify-normal relative`}>
							{site.alerts.length === 0 ? "-" : <AlertIconList site={site} />}
						</div>
					);
				} else if (mapData.id === "capacity") {
					return (
						<div
							key={mapData.id + "capacity"}
							className={`p-1 col-span-2 laptop:col-span-1 ${bgColor} ${isLast && index === 0 ? "rounded-bl-lg" : ""} ${
								isLast && index === headersOrder.length - 1 ? "rounded-br-lg" : ""
							} flex items-center justify-center laptop:justify-normal`}>
							{Number(site.totalDCCapacity.toFixed(1)).toLocaleString("en-US") + " "}kW
						</div>
					);
				} else if (mapData.id === "energy-yesterday") {
					return (
						<div
							key={mapData.id + "eyesterday"}
							className={`p-1 col-span-2 laptop:col-span-1 ${bgColor} ${isLast && index === 0 ? "rounded-bl-lg" : ""} ${
								isLast && index === headersOrder.length - 1 ? "rounded-br-lg" : ""
							} flex items-center justify-center laptop:justify-normal`}>
							{site.yesterdayEnergy + " kWh"}
						</div>
					);
				} else if (mapData.id === "energy-lifetime") {
					return (
						<div
							key={mapData.id + "elifetime"}
							className={`p-1 col-span-2 laptop:col-span-1 ${bgColor} ${isLast && index === 0 ? "rounded-bl-lg" : ""} ${
								isLast && index === headersOrder.length - 1 ? "rounded-br-lg" : ""
							} flex items-center justify-center laptop:justify-normal`}>
							{Number(site.energyLifetime).toLocaleString("en-US") + " "}kWh
						</div>
					);
				} else if (mapData.id === "trailing-7") {
					return (
						<div
							key={mapData.id + "trail7"}
							className={`p-1 col-span-2 laptop:col-span-1 ${bgColor} ${isLast && index === 0 ? "rounded-bl-lg" : ""} ${
								isLast && index === headersOrder.length - 1 ? "rounded-br-lg" : ""
							} flex items-center justify-center laptop:justify-normal`}>
							<PercentBar
								percentage={Math.ceil(site.trailing7DaysPercent)}
								barWidth={Math.round(Math.min(100, Math.max(0, Math.ceil(site.trailing7DaysPercent))))}
							/>
						</div>
					);
				} else if (mapData.id === "trailing-30") {
					return (
						<div
							key={mapData.id + "trail30"}
							className={`p-1 col-span-2 laptop:col-span-1 ${bgColor} ${isLast && index === 0 ? "rounded-bl-lg" : ""} ${
								isLast && index === headersOrder.length - 1 ? "rounded-br-lg" : ""
							} flex items-center justify-center laptop:justify-normal`}>
							<PercentBar
								percentage={Math.ceil(site.trailing30DaysPercent)}
								barWidth={Math.round(Math.min(100, Math.max(0, Math.ceil(site.trailing30DaysPercent))))}
							/>
						</div>
					);
				} else if (mapData.id === "trailing-12") {
					return (
						<div
							key={mapData.id + "trail12"}
							className={`p-1 col-span-2 laptop:col-span-1 ${bgColor} ${isLast && index === 0 ? "rounded-bl-lg" : ""} ${
								isLast && index === headersOrder.length - 1 ? "rounded-br-lg" : ""
							} flex items-center justify-center laptop:justify-normal`}>
							<PercentBar
								percentage={Math.ceil(site.trailing12Percent)}
								barWidth={Math.round(Math.min(100, Math.max(0, Math.ceil(site.trailing12Percent))))}
							/>
						</div>
					);
				} else {
					return <div>-</div>;
				}
			})}
		</>
	);
}
