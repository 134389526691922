import WeeklyBar from "../Graphs/WeeklyBar";
import { useEffect, useState, Fragment } from "react";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate, useParams } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingCircle from "../../Utility/LoadingCircle";
import { FaFileCsv } from "react-icons/fa";
import EnvironmentalImpact from "../FleetAndSite/EnvironmentalImpact";
import Trailing30Days from "../Graphs/Trailing30Days";
import { Link } from "react-router-dom";
import TicketsModal from "../FleetAndSite/TicketsModal";
import { GraphModal } from "../Graphs/GraphModal";
import Trailing13MonthsBar from "../Graphs/Trailing13MonthsBar";
import InvertersGrid from "../Inverters/InvertersGrid";
import AlertIconList from "../Alerts/AlertIconList";
import ExpectationsCalculator from "./ExpectationsCalculator";
import { BiDownArrow, BiUpArrow } from "react-icons/bi";
import ExportModal from "../FleetAndSite/ExportModal";
import FilterComponent from "../Filters/FilterComponent";
import CustomizableSiteAlias from "./CustomizableSiteAlias";

function formatCSV(data, startDate, endDate, exportLevel, granularity) {
	let csvContent = "";
	let site = "";

	if (exportLevel === "Sites") {
		site = "Site Name";
	} else {
		site = "Component";
	}

	if (granularity === "Yearly") {
		csvContent = `Date,${site},Type,Measured for Year,UW Expected for Year\n`;
	} else if (granularity === "Monthly") {
		csvContent = `Date,${site},Type,Measured for Month,UW Expected for Month\n`;
	} else {
		csvContent = `Date,${site},Type,Measured for Day,UW Expected for Day\n`;
	}

	data.forEach((location) => {
		const { name, monitoringData, expectedTableData } = location;
		const dateMap = new Map();
		const isWithinRange = (date) => {
			const currentDate = new Date(date);

			const startUTC = new Date(startDate);
			startUTC.setUTCHours(0, 0, 0, 0);

			const endUTC = new Date(endDate);
			endUTC.setUTCHours(23, 59, 59, 999);

			return currentDate >= startUTC && currentDate <= endUTC;
		};

		const filteredMeasuredData = startDate && endDate ? monitoringData.filter(({ ts }) => isWithinRange(ts)) : monitoringData;
		const filteredExpectedData = startDate && endDate ? expectedTableData.filter(({ date }) => isWithinRange(date)) : expectedTableData;

		const today = new Date().toISOString().slice(0, 10);

		filteredMeasuredData.forEach(({ ts: dateStr, kwh_measured, type }) => {
			const formattedDate = new Date(dateStr).toISOString().slice(0, 10);
			if (formattedDate > today) return;
			if (!dateMap.has(formattedDate)) {
				dateMap.set(formattedDate, {});
			}
			const typeMap = dateMap.get(formattedDate);

			if (typeMap[type]) {
				typeMap[type].measured += kwh_measured;
			} else {
				typeMap[type] = { measured: kwh_measured, expected: 0 };
			}
		});

		filteredExpectedData.forEach(({ date: dateStr, value, type }) => {
			const formattedDate = new Date(dateStr).toISOString().slice(0, 10);
			const roundedKwh = Math.round(value);
			if (formattedDate > today) return;
			if (!dateMap.has(formattedDate)) {
				dateMap.set(formattedDate, {});
			}
			const typeMap = dateMap.get(formattedDate);

			if (typeMap[type]) {
				typeMap[type].expected += roundedKwh;
			} else {
				typeMap[type] = { measured: 0, expected: roundedKwh };
			}
		});

		dateMap.forEach((typeMap, date) => {
			Object.entries(typeMap).forEach(([type, value]) => {
				if (value.measured > 0 || (value.expected > 0 && value.measured > 0)) {
					csvContent += `${date},${name},${type},${value.measured},${value.expected}\n`;
				}
			});
		});
	});

	return csvContent;
}

const downloadCSV = (csvString, filename = "data.csv") => {
	const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
	const link = document.createElement("a");
	link.href = URL.createObjectURL(blob);
	link.setAttribute("download", filename);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export default function Monitoring({ sidebarOpen, api, token, isCommercial }) {
	const navigate = useNavigate();
	const [parent, setParent] = useState(null);
	const [children, setChildren] = useState(null);
	const { user } = useAuth0();
	const [fetchingData, setFetchingData] = useState(true);
	const { id } = useParams();
	const [powerSavingValue, setPowerSavingValue] = useState(0);
	const [allTickets, setAllTickets] = useState([]);
	const [showTicketsModal, setShowTicketsModal] = useState(false);
	const [showRotateModal, setShowRotateModal] = useState(false);
	const [showExportModal, setShowExportModal] = useState(false);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [errorText, setErrorText] = useState("");
	const [totalKw, setTotalKw] = useState(0);
	const [inverters, setInverters] = useState([]);
	const [panelCleaningDate, setPanelCleaningDate] = useState("");
	const [systemCheckDate, setSystemCheckDate] = useState("");
	const [expectedTableData, setExpectedTableData] = useState([]);
	const [dailyExpectedData, setDailyExpectedData] = useState([]);
	const [emptyExpectedTable, setEmptyExpectedTable] = useState(false);
	const [filterMonitoringType, setFilterMonitoringType] = useState(false);
	const [monitoringOptions, setMonitoringOptions] = useState([]);
	const [fetchingExportData, setFetchingExportData] = useState(false);

	useEffect(() => {
		if (parent) {
			document.title = `${parent.x_studio_customer_portal_alias ? parent.x_studio_customer_portal_alias : parent.name} | Customer Portal`;
		} else {
			document.title = "Customer Portal";
		}
		return () => {
			document.title = "Customer Portal";
		};
	}, [parent]);

	function sumKWh(items) {
		return items.reduce((total, item) => {
			return total + (item.kWh || 0);
		}, 0);
	}

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		if (!user || !api || !token) {
			return;
		}
		setFetchingData(true);
		let dateForInverters = new Date();
		fetch(api + "/monitoring/" + id, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
			signal,
			body: JSON.stringify({ email: user.email, dateForInverters }),
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else {
					setFetchingData(false);
				}
			})
			.then((jsonData) => {
				if (jsonData) {
					setInverters(jsonData.inverters ? jsonData.inverters : []);
					let newParent;
					newParent = jsonData.parent;
					if (newParent.presentMonitoringTypes && newParent.presentMonitoringTypes.length > 1) {
						setMonitoringOptions(["All", ...newParent.presentMonitoringTypes]);
						setFilterMonitoringType("All");
					}
					setAllTickets(jsonData.allTickets ? jsonData.allTickets : []);
					let newTotalKw = 0;
					if (jsonData.children) {
						let powerSavingFactor = 0;
						let newPowerSavingValue = 0;
						let parentFormatted = { measuredData: [], expectedData: [], asBuiltData: [] };
						let parentMeasured = [];
						let parentExpected = [];
						let parentAsBuilt = [];
						let formattedChildren = [];
						if (jsonData.parent.name.startsWith("PS 25777")) {
							powerSavingFactor = 0.224;
						} else if (jsonData.parent.name.startsWith("PS 26910")) {
							powerSavingFactor = 0.155;
						} else if (jsonData.parent.name.startsWith("PS 21010")) {
							powerSavingFactor = 0.144;
						} else if (jsonData.parent.name.startsWith("PS 23610")) {
							powerSavingFactor = 0.235;
						}
						for (let i = 0; i < jsonData.children.length; i++) {
							if (jsonData.children[i].x_studio_meter_kw) {
								newTotalKw += jsonData.children[i].x_studio_meter_kw;
							}
							if (!jsonData.children[i].x_studio_locust_id && !jsonData.children[i].x_studio_landis_id) {
								continue;
							}
							let newChild = {};
							newChild["name"] = jsonData.children[i].name;
							newChild.meterKw = jsonData.children[i].x_studio_meter_kw;
							newChild.monitorMultiplier = jsonData.children[i].x_studio_monitor_multiplier;

							if (powerSavingFactor) {
								for (let j = 0; j < jsonData.children[i].monitoringData.length; j++) {
									newPowerSavingValue += Math.ceil(jsonData.children[i].monitoringData[j].kwh_measured) * powerSavingFactor;
								}
							}

							const shiftDateBackOneDay = (dateStr) => {
								let date = new Date(dateStr);
								date.setDate(date.getDate() - 1);
								return date.toISOString();
							};

							const today = new Date().toISOString().split("T")[0];
							const hasTodayData = jsonData.children[i].monitoringData.some((mapData) => mapData.ts.split("T")[0] === today);

							let newMeasuredData = jsonData.children[i].monitoringData.map((mapData) => {
								return newChild.monitorMultiplier > 0
									? {
											Date: hasTodayData ? shiftDateBackOneDay(mapData.ts) : mapData.ts,
											kWh: Math.ceil(mapData.kwh_measured * newChild.monitorMultiplier),
									  }
									: {
											Date: hasTodayData ? shiftDateBackOneDay(mapData.ts) : mapData.ts,
											kWh: Math.ceil(mapData.kwh_measured),
									  };
							});
							let newExpectedData = jsonData.children[i].monitoringData.map((mapData) => {
								return {
									Date: hasTodayData ? shiftDateBackOneDay(mapData.ts) : mapData.ts,
									kWh: Math.floor(mapData.kwh_expected),
								};
							});
							let newAsBuiltData = jsonData.children[i].monitoringData.map((mapData) => {
								return {
									Date: hasTodayData ? shiftDateBackOneDay(mapData.ts) : mapData.ts,
									kWh: Math.floor(mapData.kwh_as_built),
								};
							});
							newChild.measuredData = newMeasuredData;
							newChild.expectedData = newExpectedData;
							newChild.asBuiltData = newAsBuiltData;
							newChild.monitoringType = newParent.monitoringType;
							parentMeasured = [...parentMeasured, ...newMeasuredData];
							parentExpected = [...parentExpected, ...newExpectedData];
							parentAsBuilt = [...parentAsBuilt, ...newAsBuiltData];
							formattedChildren.push(newChild);
						}

						parentMeasured.sort((a, b) => new Date(a.Date) - new Date(b.Date));
						parentExpected.sort((a, b) => new Date(a.Date) - new Date(b.Date));
						parentAsBuilt.sort((a, b) => new Date(a.Date) - new Date(b.Date));

						parentFormatted.measuredData = parentMeasured;
						parentFormatted.expectedData = parentExpected;
						parentFormatted.asBuiltData = parentAsBuilt;

						newParent = { ...newParent, ...parentFormatted };

						formattedChildren.sort(function (obj1, obj2) {
							return obj2.meterKw - obj1.meterKw;
						});
						setChildren(formattedChildren);
						setPowerSavingValue(newPowerSavingValue);
					}

					const formattedData = jsonData.expectedTableData.flatMap(({ year, month, value }) => {
						const daysInMonth = new Date(year, month, 0).getDate();
						const dailyValue = value / daysInMonth;
						const today = new Date().toISOString().split("T")[0];

						return Array.from({ length: daysInMonth }, (_, day) => {
							const date = new Date(year, month - 1, day + 1).toISOString();
							return { Date: date, kWh: dailyValue };
						}).filter(({ Date }) => Date.split("T")[0] < today);
					});

					setTotalKw(newTotalKw);
					setParent(newParent);
					setPanelCleaningDate(jsonData.latestPanelCleaning);
					setSystemCheckDate(jsonData.latestSystemCheck);
					setExpectedTableData(jsonData.expectedTableData);
					setDailyExpectedData(formattedData);
					setEmptyExpectedTable(jsonData.emptyExpectedTable);
					setFetchingData(false);
				}
			});
		return () => controller.abort();
	}, [api, id, token, user]);

	const changeMonitoringFilter = (e) => {
		setFilterMonitoringType(e.target.value);
	};

	const exportData = (startDate, endDate, monitoringType, exportLevel, ptoFilter, granularity) => {
		if (startDate && !endDate) {
			setErrorText("Please enter an end date");
			return;
		}
		if (endDate && !startDate) {
			setErrorText("Please enter a start date");
			return;
		}
		if (startDate > endDate) {
			setErrorText("Start date must be before end date");
			return;
		}
		if (startDate > new Date().toISOString().split("T")[0] || endDate > new Date().toISOString().split("T")[0]) {
			setErrorText("Dates must be before today");
			return;
		}
		if (fetchingExportData) {
			return;
		}
		if (parent) {
			// const csvString = convertToCSV(combineAndSumData(children));
			setFetchingExportData(true);
			setErrorText("");
			fetch(api + "/monitoring/export", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({
					startDate,
					endDate,
					monitoringType: filterMonitoringType ? filterMonitoringType : parent.monitoringType,
					parentSites: [parent.id],
					exportLevel,
					granularity
				}),
			})
				.then((res) => {
					if (res.ok) {
						return res.json();
					} else {
						setFetchingExportData(false);
					}
				})
				.then((jsonData) => {
					const csvString = formatCSV(jsonData.csvData, startDate, endDate, exportLevel, granularity);
					downloadCSV(csvString, `${parent.x_studio_customer_portal_alias ? parent.x_studio_customer_portal_alias : parent.name} - monitoring.csv`);
					setFetchingExportData(false);
				});
		}
	};

	const closeExportModal = () => {
		setStartDate("");
		setEndDate("");
		setErrorText("");
		setShowExportModal(false);
	};

	function extractLastLetterIfPatternMet(s, index) {
		const match = s.match(/[A-Za-z]$/);
		return match ? match[0] : index;
	}

	const formatDate = (dateString) => {
		if (!dateString) return "";
		const date = new Date(dateString);
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");
		const year = date.getFullYear();
		return `${month}-${day}-${year}`;
	};

	return (
		<>
			<div
				className={`${
					user.email === "ps@1stle.com"
						? "bg-PSBackgroundImage"
						: user.email === "ess@1stle.com"
						? "bg-ESSBackgroundImage"
						: isCommercial
						? "bg-CommercialBackgroundImage"
						: "bg-1stleBackgroundImage"
				} fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover`}></div>
			{showTicketsModal ? (
				<TicketsModal allTickets={allTickets} user={user} projectId={id} setShowTicketsModal={setShowTicketsModal} isCommercial={isCommercial} />
			) : null}

			{/* <div className=" bg-slate-200/[.29] fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover"></div> */}
			<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
				<div className="m-10 p-4 bg-slate-50 bg-opacity-95">
					<div className={`flex ${!isCommercial ? "justify-end" : "justify-between"}`}>
						{isCommercial ? (
							<div
								className={`p-3 flex items-center justify-center gap-2 w-32 cursor-pointer ${
									user.email === "ps@1stle.com"
										? "bg-[#FFAC1C] hover:bg-orange-500"
										: user.email === "ess@1stle.com"
										? "bg-lime-400 hover:bg-lime-500"
										: "bg-[#FFEA00] hover:bg-yellow-400"
								}`}
								onClick={() => (window.history.state && window.history.state.idx > 0 ? navigate(-1) : navigate("/monitoring"))}>
								<IoArrowBackCircle className="text-2xl cursor-pointer" />
								<p className="cursor-pointer">Go back</p>
							</div>
						) : null}
						{!fetchingData && children && children.length > 0 ? (
							<div
								className="p-3 flex items-center gap-2 bg-white w-32 cursor-pointer border border-black rounded-lg select-none"
								onClick={() => setShowExportModal(true)}>
								<FaFileCsv className="text-3xl cursor-pointer" />
								<p className="cursor-pointer">Export</p>
							</div>
						) : null}
					</div>

					{/* <div className="bg-white p-2 border border-black mt-4 mb-4 sticky ">
					<h2 className="text-center text-2xl font-bold">Historical Data</h2>
				</div> */}
					{fetchingData ? (
						<div className="m-4 flex flex-col justify-center items-center">
							<LoadingCircle />
							<p className="font-bold">Retrieving your data...</p>
						</div>
					) : parent ? (
						<>
							<CustomizableSiteAlias record={parent} setRecord={setParent} user={user} isCommercial={isCommercial} api={api} token={token} />
							<div className="flex gap-4">
								<div className="w-full ">
									<div className=" flex flex-col h-full">
										<div className="gap-4 h-full md:flex">
											<div className="w-full flex flex-col border border-black  rounded-lg bg-black mb-4 md:mb-0">
												<p className="text-lg text-white p-2 font-bold text-center">Site Overview</p>
												<div className="p-4 flex flex-col gap-2 bg-white rounded-b-lg h-full">
													<div className="flex justify-between border-b-2 border-gray gap-2">
														<p className="text-xs md:text-base font-bold">Location</p>
														{parent.google_map_link ? (
															<p
																dangerouslySetInnerHTML={{ __html: parent.google_map_link }}
																className="text-sm md:text-base text-blue-500 hover:text-blue-700 underline"
															/>
														) : (
															"-"
														)}
													</div>
													<div className="flex justify-between border-b-2 border-gray">
														<p className="text-xs md:text-base font-bold">Generated to Date</p>
														<p>{Number(sumKWh(parent.measuredData).toFixed(1)).toLocaleString("en-US") + " "}kWh</p>
													</div>
													<div className="flex justify-between border-b-2 border-gray">
														<p className="text-xs md:text-base font-bold">PV System Size</p>
														<p>{Number(totalKw.toFixed(2)).toLocaleString("en-US") + " "}kW</p>
													</div>
													<div className="flex justify-between border-b-2 border-gray">
														<p className="text-xs md:text-base font-bold">Monitoring Type</p>
														<p>{parent.monitoringType ? parent.monitoringType : "-"}</p>
													</div>
												</div>
											</div>

											<EnvironmentalImpact generatedToDate={sumKWh(parent.measuredData)} powerSavingValue={powerSavingValue} />
										</div>
										<div className="w-full flex flex-col border border-black rounded-lg bg-black mt-4 md:mb-0">
											<p className="text-lg text-white p-2 font-bold text-center">Performance & Maintenance</p>
											<div className=" flex flex-col md:flex-row bg-white rounded-b-lg h-full w-full">
												<div className="flex flex-col items-center justify-between w-full gap-4 p-2 md:border-r border-b md:border-b-0 border-black">
													<p className="text-xs md:text-base font-bold">Tickets</p>
													<p
														onClick={() => setShowTicketsModal(true)}
														className="cursor-pointer text-blue-500 hover:text-blue-700 underline">
														{allTickets ? Number(allTickets.length).toLocaleString("en-US") : 0}
													</p>
												</div>

												<div className="flex flex-col items-center justify-between w-full gap-4 p-2 md:border-r border-b md:border-b-0 border-black">
													<p className="text-xs md:text-base font-bold">Alerts</p>
													<AlertIconList site={parent} />
												</div>

												<div className="flex flex-col items-center justify-between w-full gap-4 p-2 md:border-r border-b md:border-b-0 border-black">
													<p className="text-xs md:text-base font-bold">System Turn On Date</p>
													<p>{parent.x_studio_system_turned_on_date_p ? formatDate(parent.x_studio_system_turned_on_date_p) : "-"}</p>
												</div>

												<div className="flex flex-col items-center justify-between w-full gap-4 p-2 md:border-r border-b md:border-b-0 border-black">
													<p className="text-xs md:text-base font-bold">Panel Cleaning</p>
													<p>{panelCleaningDate ? formatDate(panelCleaningDate) : "-"}</p>
												</div>

												<div className="flex flex-col items-center justify-between w-full gap-4 p-2 border-black">
													<p className="text-xs md:text-base font-bold">System Check</p>
													<p>{systemCheckDate ? formatDate(systemCheckDate) : "-"}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="flex flex-col justify-center items-center mt-4 bg-white p-4 rounded-lg border border-black">
								<p className="text-2xl font-bold ">System Overview</p>
							</div>

							<div
								className={`p-3 mt-4 sm:hidden flex items-center justify-center w-auto cursor-pointer ${
									user.email === "ps@1stle.com"
										? "bg-[#FFAC1C] hover:bg-orange-500"
										: user.email === "ess@1stle.com"
										? "bg-lime-400 hover:bg-lime-500"
										: isCommercial
										? "bg-[#FFEA00] hover:bg-yellow-400"
										: "bg-blue-500 hover:bg-blue-600"
								}`}
								onClick={() => setShowRotateModal(true)}>
								<p className="font-bold cursor-pointer">View Graphs</p>
							</div>

							<div className="text-color-black w-full hidden sm:grid grid-cols-1 md:grid-cols-2 grid-rows-2 gap-4 mt-0 sm:mt-4">
								<Trailing13MonthsBar
									measuredData={parent.measuredData}
									expectedData={dailyExpectedData}
									asBuiltData={parent.asBuiltData}
									expandedByDefault={true}
									hasAsBuilt={parent.hasAsBuilt}
									isPublicStorage={user.email === "ps@1stle.com"}
								/>
								<Trailing30Days
									measuredData={parent.measuredData}
									expectedData={dailyExpectedData}
									asBuiltData={parent.asBuiltData}
									hasAsBuilt={parent.hasAsBuilt}
									isPublicStorage={user.email === "ps@1stle.com"}
								/>
								<WeeklyBar
									measuredData={parent.measuredData}
									expectedData={dailyExpectedData}
									asBuiltData={parent.asBuiltData}
									hasAsBuilt={parent.hasAsBuilt}
									isPublicStorage={user.email === "ps@1stle.com"}
								/>
							</div>
							{inverters && inverters.length > 0 ? <InvertersGrid inverters={inverters} /> : null}
						</>
					) : (
						<>
							<div className="mt-4 flex flex-col items-center">
								<p className="font-bold text-2xl mb-2">This site's data is currently being migrated.</p>
								<p className="font-bold text-xl mb-4">
									Please check over on the{" "}
									<Link to="/monitoring" className="text-blue-600 hover:text-blue-700 underline">
										Monitoring
									</Link>{" "}
									tab to see the sites with data available.
								</p>
							</div>
						</>
					)}
					<div>
						<div className={`grid grid-cols-1 mt-4 gap-4`}>
							{fetchingData ? null : children && children.length > 1 ? (
								<>
									<div className="flex flex-col justify-center items-center mt-4 bg-white p-4 rounded-lg border border-black">
										<p className="text-2xl font-bold ">Meters</p>
									</div>

									<div
										className={`p-3 sm:hidden flex items-center justify-center w-auto cursor-pointer ${
											user.email === "ps@1stle.com"
												? "bg-[#FFAC1C] hover:bg-orange-500"
												: user.email === "ess@1stle.com"
												? "bg-lime-400 hover:bg-lime-500"
												: isCommercial
												? "bg-[#FFEA00] hover:bg-yellow-400"
												: "bg-blue-500 hover:bg-blue-600"
										}`}
										onClick={() => setShowRotateModal(true)}>
										<p className="font-bold cursor-pointer">View Graphs</p>
									</div>

									{children.map((mapData, index) => {
										return (
											//className={`${index % 2 === 0 ? "bg-white" : "bg-gray-300"}`}
											<div key={index}>
												<div className="flex flex-col justify-center items-center text-lg md:text-2xl font-bold mb-4  ">
													<p>Meter {extractLastLetterIfPatternMet(mapData.name, index)}</p>
													<p className="font-normal text-sm">{`${mapData.meterKw.toFixed(1)} kW`}</p>
												</div>

												<div className="text-color-black w-full hidden sm:grid grid-cols-1 md:grid-cols-2 grid-rows-2 gap-4 mb-4">
													<Trailing13MonthsBar
														measuredData={mapData.measuredData}
														expectedData={dailyExpectedData}
														asBuiltData={mapData.asBuiltData}
														expandedByDefault={true}
														hasAsBuilt={parent.hasAsBuilt}
														isPublicStorage={user.email === "ps@1stle.com"}
													/>
													<Trailing30Days
														measuredData={mapData.measuredData}
														expectedData={dailyExpectedData}
														asBuiltData={mapData.asBuiltData}
														hasAsBuilt={parent.hasAsBuilt}
														isPublicStorage={user.email === "ps@1stle.com"}
													/>
													<WeeklyBar
														measuredData={mapData.measuredData}
														expectedData={dailyExpectedData}
														asBuiltData={mapData.asBuiltData}
														hasAsBuilt={parent.hasAsBuilt}
														isPublicStorage={user.email === "ps@1stle.com"}
													/>
												</div>
											</div>
										);
									})}
								</>
							) : null}
						</div>
					</div>

					{isCommercial ? (
						fetchingData ? null : parent ? (
							!emptyExpectedTable ? (
								<>
									<div className="flex justify-center items-center mb-4 bg-white p-4 rounded-lg border border-black">
										<p className="text-2xl font-bold text-center flex-grow">Expectations Table</p>
									</div>
									<ExpectationsCalculator expectedTableData={expectedTableData} />
								</>
							) : null
						) : null
					) : null}

					{/* 
					{fetchingData ? null : (
						<div className="mt-4 flex justify-center">
							<a
								className="text-blue-400 hover:text-blue-600 cursor-pointer underline"
								href={process.env.REACT_APP_TROUBLESHOOTING_PDF_URL}
								download>
								Experiencing difficulties? Please consult our Troubleshooting FAQ for assistance
							</a>
						</div>
					)} */}

					{showRotateModal ? <GraphModal setShowRotateModal={setShowRotateModal} /> : null}
					{showExportModal ? (
						fetchingExportData ? (
							<div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-gray-700 bg-opacity-50 z-[200]">
								<div className="p-4 flex flex-col justify-center items-center bg-white rounded-lg">
									<LoadingCircle />
									<p className="font-bold">Exporting...</p>
								</div>
							</div>
						) : (
							<ExportModal
								startDate={startDate}
								endDate={endDate}
								setStartDate={setStartDate}
								setEndDate={setEndDate}
								exportData={exportData}
								closeExportModal={closeExportModal}
								errorText={errorText}
								filterMonitoringType={filterMonitoringType}
								MonitoringFilterComponent={
									<FilterComponent
										title={"Monitoring Types"}
										changeFilter={changeMonitoringFilter}
										filter={filterMonitoringType}
										options={monitoringOptions}
									/>
								}
							/>
						)
					) : null}

					<p className="text-center text-xs text-underline">Meter data refreshes daily at 2 AM PT</p>
					<p className="text-center text-xs text-underline">Inverter data refreshes daily at 11 AM and 3PM PT</p>
				</div>
			</div>
		</>
	);
}
