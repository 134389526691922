import { useEffect, useState } from "react";
import ApproveModal from "./ApproveModal";
import DenyModal from "./DenyModal";

export const ViewDesign = ({ api, token, isCommercial, sidebarOpen, pdfUrl, setPdfUrl, parent, name, designApprovalDate }) => {
    const [showDesign, setShowDesign] = useState(true);
    const [showApproveModal, setshowApproveModal] = useState(false);
    const [showDenyModal, setShowDenyModal] = useState(false);

    const handleApprove = (e) => {
        e.stopPropagation();
        setshowApproveModal(true);
    }

    const handleDeny = (e) => {
        e.stopPropagation();
        setShowDenyModal(true);
    }

    return (
        showDesign ? (
            <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black/50 z-50" onClick={() => setShowDesign(false)}>
                <div style={{ width: "50%", height: "80%", position: "relative", boxShadow: "none", borderRadius: "10px", overflow: "hidden" }} className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
                    <div className="flex items-center justify-end">
                        <button className="inline-flex items-center py-2.5 px-4 text-md font-medium text-center text-white bg-gray-400 rounded focus:ring-4 focus:ring-primary-200 hover:bg-gray-500" onClick={() => setShowDesign(false)}>Close</button>
                    </div>
                    <iframe
                        title="Design"
                        src={pdfUrl}
                        style={{ width: "100%", height: "calc(100% - 90px)"}}
                        frameBorder="0"
                        loading="lazy">
                    </iframe>
                    <div className="flex items-center justify-between">
                        {!designApprovalDate && (
                            <>
                                <button className="inline-flex items-center py-2.5 px-4 text-md font-medium text-center text-white bg-blue-500 rounded focus:ring-4 focus:ring-primary-200 hover:bg-blue-600" onClick={handleApprove}>Approve</button>
                                <button className="inline-flex items-center py-2.5 px-4 text-md font-medium text-center text-white bg-red-500 rounded focus:ring-4 focus:ring-red-200 hover:bg-red-600" onClick={handleDeny}>Deny</button>
                            </>
                        )}
                    </div>
                </div>
                {showApproveModal && 
                    <ApproveModal api={api} token={token} isCommercial={isCommercial} parent={parent} setshowApproveModal={setshowApproveModal} setShowDesign={setShowDesign} />
                }
                {showDenyModal && 
                    <DenyModal api={api} token={token} isCommercial={isCommercial} parent={parent} name={name} setShowDenyModal={setShowDenyModal} setShowDesign={setShowDesign} setPdfUrl={setPdfUrl} />
                }
            </div>
        ) : null
    )
}