import { useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import LoadingCircle from "../../Utility/LoadingCircle";

const CustomizableSiteAlias = ({ api, token, record, setRecord, user, isCommercial }) => {
	const [openModal, setOpenModal] = useState(false);
	const [inputAlias, setInputAlias] = useState("");
	const [loading, setLoading] = useState(false);

	const handleClick = () => {
		setOpenModal(true);
	};

	const handleModalClose = () => {
		setOpenModal(false);
	};

	const updateInputAlias = (e) => {
		setInputAlias(e.target.value);
	};

	const updateAlias = () => {
		setLoading(true);
		fetch(api + "/monitoring/alias", {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({ projectId: record.id, newAlias: inputAlias }),
		}).then((res) => {
			if (res.ok) {
				let tempRecord = { ...record };
				tempRecord["x_studio_customer_portal_alias"] = inputAlias;
				setRecord(tempRecord);
				setLoading(false);
				setOpenModal(false);
			} else {
				alert("Failed to update alias");
			}
		});
	};

	return (
		<>
			{loading ? (
				<div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-gray-700 bg-opacity-50 z-[200]">
					<div className="p-4 flex flex-col justify-center items-center bg-white rounded-lg">
						<LoadingCircle />
						<p className="font-bold">Updating...</p>
					</div>
				</div>
			) : null}
			{openModal && !loading ? (
				<div
					className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-gray-700 bg-opacity-50 z-[200]"
					onClick={handleModalClose}>
					<div
						className="flex flex-col items-center justify-center bg-white p-4 m-2 rounded-lg gap-2 w-full z-[100] md:w-2/5 md:max-w-3/5"
						onClick={(e) => e.stopPropagation()}>
						<p className="font-bold text-2xl px-1 mb-3">New Site Alias</p>
						<input type="text" placeholder="" value={inputAlias} onChange={updateInputAlias} className="p-1 border border-black w-full" />
						<div className="flex gap-2 justify-end w-full">
							<div>
								<button className="px-3 py-2 w-full bg-gray-300 hover:bg-gray-500 hover:text-white" onClick={handleModalClose}>
									Cancel
								</button>
							</div>
							<div>
								<button
									onClick={updateAlias}
									className={`px-3 py-2 w-full text-white ${
										user.email === "ps@1stle.com"
											? "bg-orange-500 hover:bg-orange-600"
											: user.email === "ess@1stle.com"
											? "bg-green-500 hover:bg-green-600"
											: isCommercial
											? "bg-yellow-400 hover:bg-yellow-500"
											: "bg-blue-500 hover:bg-blue-600"
									}`}>
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
			) : null}

			<div className="flex flex-col justify-center items-center  mt-4 md:mt-0 mb-4 ">
				<div className="flex justify-center items-center gap-2">
					<h2 className="text-2xl md:text-3xl font-bold">
						{record.x_studio_customer_portal_alias ? record.x_studio_customer_portal_alias : record.name}
					</h2>
					<button onClick={handleClick}>
						<AiFillEdit className="text-2xl text-blue-600" />
					</button>
				</div>
				{record.x_studio_customer_portal_alias ? <p className="text-xs">{record.name}</p> : null}
			</div>
		</>
	);
};

export default CustomizableSiteAlias;
