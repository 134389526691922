import React from 'react';

function ApproveModal({ api, token, isCommercial, parent, setshowApproveModal, setShowDesign }) {

    const handleClose = (e) => {
        e.stopPropagation();
        setshowApproveModal(false);
    }

    const handleApprove = (e) => {
        e.stopPropagation();
        setshowApproveModal(false);
        
        try {
            fetch(api + "/design/approve", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify({
                    id: isCommercial ? parent : parent[0],
                })
            }).then((res) => {
                if (res.ok) {
                    alert("Thank you. The design has been approved.");
                    setshowApproveModal(false);
                    setShowDesign(false);
                    window.location.reload();
                    res.json();
                }
            });
        } catch (error) {
        }
    }

	return (
        <div id="approveModal" tabindex="-1" aria-hidden="true" className="fixed left-0 top-0 w-screen h-screen flex justify-center items-center bg-black/50 z-[100]" onClick={handleClose}>
            <div className="relative p-4 w-full max-w-md h-full md:h-auto" onClick={(e) => e.stopPropagation()}>
                <div className="relative p-4 text-center bg-white rounded-lg shadow sm:p-5">
                    <button type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="deleteModal" onClick={handleClose}>
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <p className="mb-4 text-gray-500">Are you sure you want to approve this design?</p>
                    <div className="flex justify-center items-center space-x-4">
                        <button type="submit" className="py-2 px-3 text-sm font-medium text-center text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300" onClick={handleApprove}>
                            Yes, I'm sure
                        </button>
                        <button data-modal-toggle="approveModal" type="button" className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10" onClick={handleClose}>
                            No, cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
	);
}

export default ApproveModal;