
export const Disclaimer = ({ closeDisclaimer, showDesign }) => {

    return (
        <div id="disclaimer" tabIndex="-1" className="fixed left-0 top-0 w-screen h-screen flex justify-center items-center bg-black/50 z-[1000]" onClick={closeDisclaimer}>
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow" onClick={(e) => e.stopPropagation()}>
                    <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="disclaimer" onClick={closeDisclaimer}>
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500">The design will be considered approved if a time lapse of 1 week has occurred since the initial notification of the completion of the design.</h3>
                        <button data-modal-hide="disclaimer" type="button" className="inline-flex items-center py-2.5 px-4 text-md font-medium text-center text-white bg-blue-500 rounded focus:ring-4 focus:ring-primary-200 hover:bg-blue-600" onClick={showDesign}>
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}