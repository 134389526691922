import { useState } from "react";

export default function ExportModal({
	startDate,
	endDate,
	setStartDate,
	setEndDate,
	exportData,
	closeExportModal,
	errorText,
	setMonitoringType,
	filterMonitoringType,
	PTOFilterComponent,
	MonitoringFilterComponent,
	ptoFilter,
}) {
	const [exportLevel, setExportLevel] = useState("Sites");
	const [granularity, setGranularity] = useState("Daily");
	const exportOptions = ["Sites", "Meters"];
	const granularityOptions = ["Daily", "Monthly", "Yearly"];
	const handleLevelChange = (e) => {
		setExportLevel(e.target.value);
	};
	const handleGranularityChange = (e) => {
		setGranularity(e.target.value);
	}
	return (
		<div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-gray-700 bg-opacity-50 z-[100]" onClick={closeExportModal}>
			<div className="flex flex-col items-center justify-center bg-white p-4 m-2 rounded-lg" onClick={(e) => e.stopPropagation()}>
				<h2 className="text-2xl font-bold mb-4">Please Select The Dates You Would Like To Export</h2>
				<div className="flex items-center mb-4 w-60">
					<label htmlFor="start-date" className="text-lg font-bold mr-2 w-full">
						Start Date:{" "}
					</label>
					<input
						type="date"
						id="start-date"
						className="p-2 border border-black rounded-lg flex-grow"
						onChange={(e) => setStartDate(e.target.value)}
					/>
				</div>
				<div className="flex items-center mb-4 w-60">
					<label htmlFor="end-date" className="text-lg font-bold mr-2 w-full">
						End Date:{" "}
					</label>
					<input type="date" id="end-date" className="p-2 border border-black rounded-lg flex-grow" onChange={(e) => setEndDate(e.target.value)} />
				</div>
				<div className="flex items-center mb-4 w-60">
					<label htmlFor="start-date" className="text-lg font-bold mr-2 w-full">
						Export Level:{" "}
					</label>
					<select value={exportLevel} onChange={handleLevelChange} className="p-2 border border-black rounded-lg flex-grow">
						{exportOptions.map((option, index) => (
							<option key={index} value={option}>
								{option}
							</option>
						))}
					</select>
				</div>
				{filterMonitoringType && MonitoringFilterComponent ? MonitoringFilterComponent : null}
				{PTOFilterComponent}
				<div className="flex items-center mb-4 w-60">
					<label htmlFor="granularity" className="text-lg font-bold mr-2 w-full">
						Granularity:{" "}
					</label>
					<select value={granularity} onChange={handleGranularityChange} className="p-2 border border-black rounded-lg flex-grow">
						{granularityOptions.map((option, index) => (
							<option key={index} value={option}>
								{option}
							</option>
						))}
					</select>
				</div>
				{errorText ? <p className="text-red-500 text-sm mb-4">{errorText}</p> : null}
				<div className="flex items-center justify-center w-full gap-4">
					<button onClick={closeExportModal} className="bg-gray-500 text-white px-4 py-2 rounded-lg">
						Close
					</button>
					<button
						onClick={() => exportData(startDate, endDate, filterMonitoringType, exportLevel, ptoFilter, granularity)}
						className="bg-blue-500 text-white px-4 py-2 rounded-lg">
						Export
					</button>
				</div>
			</div>
		</div>
	);
}
