import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useRef, useState } from "react";
import { PaginationNav1Presentation } from "./Utility/Pagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { HiEllipsisVertical } from "react-icons/hi2";
import LoadingCircle from "./Utility/LoadingCircle";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { ViewDesign } from "./Design/ViewDesign";
import { Disclaimer } from "./Design/Disclaimer";

export const Installation = ({ api, sidebarOpen, token, isCommercial }) => {
	const { user } = useAuth0();
	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [zip, setZip] = useState("");
	const [systemPrice, setSystemPrice] = useState("");
	const [contractSignedDate, setContractSignedDate] = useState("");
	const [installDate, setInstallDate] = useState("");
	const [systemSize, setSystemSize] = useState("");
	const [designCompletedDate, setDesignCompletedDate] = useState("");
	const [designApprovalDate, setDesignApprovalDate] = useState("");
	const [searchParams] = useSearchParams();
	const [searchQuery, setSearchQuery] = useState("");
	const [pdfUrl, setPdfUrl] = useState("");
	const [installation, setInstallation] = useState([]);
	const [parent, setParent] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [designLoading, setDesignLoading] = useState(false);
	const [ascending, setAscending] = useState(false);
	const [showDisclaimer, setShowDisclaimer] = useState(false);
	const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
	const [pageCount, setPageCount] = useState(0);
	const [firstIndex, setFirstIndex] = useState(0);
	const [lastIndex, setLastIndex] = useState(0);
	const [globalPage, setGlobalPage] = useState(0);
	const [resiInstallation, setResiInstallation] = useState(null);
	const navigate = useNavigate();

	const [openSelectionMenu, setOpenSelectionMenu] = useState(false);
	const [contractStatusSelected, setContractStatusSelected] = useState(true);
	const [systemSizeSelected, setSystemSizeSelected] = useState(true);
	const [systemOnDate, setSystemOnDateSelected] = useState(true);
	const [monitoringSelected, setMonitoringSelected] = useState(true);
	const [servicesSelected, setServicesSelected] = useState(true);
	const [ticketsSelected, setTicketsSelected] = useState(true);
	const projectSelected = useRef(true);

	const [headers, setHeaders] = useState([
		{ id: "project", title: "Project", field: "name", sorted: false },
		{ id: "contractStatus", title: "Contract Status", field: "x_studio_contact_status" },
		{ id: "systemSize", title: "System Size", field: "x_studio_contract_system_size_kw_1" },
		{ id: "systemOnDate", title: "System on Date", field: "x_studio_system_turned_on_date_p" },
		{ id: "monitoring", title: "Monitoring", link: true },
		{ id: "services", title: "Site Info", link: true },
		{ id: "tickets", title: "Tickets", link: true },
	]);

	const installationData = installation.slice(firstIndex, lastIndex + 1);

	useEffect(() => {
		if (api === "" || !token || isCommercial || !designCompletedDate) {
			return;
		}
		try {
			setDesignLoading(true);
			fetch(api + "/design", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({
					id: parent ? parent[0] : "",
					disclaimerAccepted: disclaimerAccepted,
					parentName: parent ? parent[1] : "",
					isCommercial: isCommercial,
				}),
				mode: "cors",
			})
				.then((res) => {
					if (res.ok) {
						const contentType = res.headers.get("Content-Type");
						if (contentType.includes("application/pdf")) {
							return res.blob().then((blob) => {
								const url = URL.createObjectURL(blob);
								setPdfUrl(url);
							});
						} else if (contentType.includes("application/json")) {
							return res.json().then((data) => {
								setDesignApprovalDate(data.approvalDate);
							});
						}
					}
				})
				.catch((err) => {})
				.finally(() => {
					setDesignLoading(false);
				});
		} catch (error) {}
	}, [api, token, isCommercial, user.email, parent, disclaimerAccepted, designCompletedDate]);

	useEffect(() => {
		if (api === "" || !token) {
			return;
		}

		try {
			setIsLoading(true);
			fetch(api + "/installation", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({
					userEmail: user.email,
					isCommercial: isCommercial,
				}),
				mode: "cors",
			})
				.then((res) => {
					return res.json();
				})
				.then((res) => {
					setIsLoading(false);
					if (isCommercial) {
						setInstallation(res.installation);
					} else {
						setName(res.name);
						setPhone(res.phone);
						setStreet(res.street);
						setCity(res.city);
						setState(res.state);
						setZip(res.zip);
						setSystemPrice(res.systemPrice);
						setContractSignedDate(res.contractSignedDate);
						setInstallDate(res.installDate);
						setSystemSize(res.systemSize);
						setDesignCompletedDate(res.designCompletedDate);
						setParent(res.parent);
						setResiInstallation(res.installation);
					}
				});
		} catch (error) {}
	}, [api, token, user.email, isCommercial]);

	useEffect(() => {
		setPageCount(Math.ceil(installation.length / 10));
		setFirstIndex(globalPage * 10);
		setLastIndex(10 * (globalPage + 1) < installation.length ? 10 * (globalPage + 1) - 1 : installation.length - 1);
	}, [installation, globalPage]);

	useEffect(() => {
		if (api === "" || !token || !isCommercial) {
			return;
		}

		let query = "?";
		const searchParams = new URLSearchParams(window.location.search);
		const queryParam = searchParams.get("query");
		if (queryParam) {
			const encodedQuery = encodeURIComponent(queryParam);
			query = `?query=${encodedQuery}`;
			let decodedQuery = decodeURIComponent(encodedQuery);
			setSearchQuery(decodedQuery === " " ? "" : decodedQuery);
		}

		const controller = new AbortController();
		const signal = controller.signal;
		setIsLoading(true);
		setInstallation([]);
		fetch(api + "/installation/search" + query, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				userEmail: user.email,
				isCommercial: isCommercial,
			}),
			mode: "cors",
			signal,
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				}
			})
			.then((res) => {
				setIsLoading(false);
				if (isCommercial) {
					setInstallation(res.projects);
				}
			})
			.catch(function (error) {});
		return () => {
			controller.abort();
		};
	}, [api, searchParams, token, user.email, isCommercial]);

	const searchProjects = (e) => {
		e.preventDefault();

		const uriEncodedString = encodeURIComponent(searchQuery.trim());
		if (searchQuery !== "") {
			navigate(`/installation/search?query=${uriEncodedString}`);
		} else {
			navigate(`/installation/search?query=${encodeURIComponent(" ")}`);
		}
	};

	const currencyFormat = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	const dateFormat = (inputDate) => {
		var date = new Date(inputDate);
		if (!isNaN(date.getTime())) {
			return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
		}
	};

	const isHeaderSelected = (headerId) => {
		switch (headerId) {
			case "contractStatus":
				return contractStatusSelected;
			case "systemSize":
				return systemSizeSelected;
			case "systemOnDate":
				return systemOnDate;
			case "monitoring":
				return monitoringSelected;
			case "services":
				return servicesSelected;
			case "tickets":
				return ticketsSelected;
			default:
				return projectSelected;
		}
	};

	const handleDragEnd = (result) => {
		if (!result.destination) return;

		const updatedColumnOrder = Array.from(headers);
		const [movedColumn] = updatedColumnOrder.splice(result.source.index, 1);

		updatedColumnOrder.splice(result.destination.index, 0, movedColumn);

		setHeaders(updatedColumnOrder);
	};

	const handleSortProjects = (headerId) => {
		const sortedInstallationData = [...installation];

		if (headerId === "project") {
			const projectHeader = headers.find((header) => header.id === headerId);
			const currentSorted = projectHeader.sorted;

			sortedInstallationData.sort((a, b) => {
				const nameA = getProjectNames(a.name);
				const nameB = getProjectNames(b.name);

				let comparison = 0;
				if (nameA > nameB) {
					comparison = 1;
				}
				if (nameA < nameB) {
					comparison = -1;
				}
				return currentSorted ? comparison * -1 : comparison;
			});
			projectHeader.sorted = !currentSorted;

			setInstallation(sortedInstallationData);
			setHeaders([...headers]);
			setAscending(!ascending);
		} else if (headerId === "systemSize") {
			const systemSizeHeader = headers.find((header) => header.id === headerId);
			const currentSorted = systemSizeHeader.sorted;

			const sorted = ascending ? -1 : 1;

			sortedInstallationData.sort((a, b) => {
				const sizeA = a.x_studio_contract_system_size_kw_1;
				const sizeB = b.x_studio_contract_system_size_kw_1;

				return sorted * (sizeA - sizeB);
			});
			systemSizeHeader.sorted = !currentSorted;

			setInstallation(sortedInstallationData);
			setHeaders([...headers]);
			setAscending(!ascending);
		} else if (headerId === "systemOnDate") {
			const systemOnDateHeader = headers.find((header) => header.id === headerId);
			const currentSorted = systemOnDateHeader.sorted;

			sortedInstallationData.sort((a, b) => {
				const dateA = a.x_studio_system_turned_on_date_p ? a.x_studio_system_turned_on_date_p : "";
				const dateB = b.x_studio_system_turned_on_date_p ? b.x_studio_system_turned_on_date_p : "";

				let comparison = 0;
				if (dateA > dateB) {
					comparison = 1;
				}
				if (dateA < dateB) {
					comparison = -1;
				}
				return currentSorted ? comparison * -1 : comparison;
			});
			systemOnDateHeader.sorted = !currentSorted;

			setInstallation(sortedInstallationData);
			setHeaders([...headers]);
			setAscending(!ascending);
		}
	};

	const getProjectNames = (projectName) => {
		const name = projectName.split("-");
		if (name.length >= 2) {
			return name[0].trim();
		} else {
			return projectName.trim();
		}
	};

	const showDesign = () => {
		setDisclaimerAccepted(true);
		setShowDisclaimer(false);
	};

	const closeDisclaimer = () => {
		setDisclaimerAccepted(false);
		setShowDisclaimer(false);
	};

	if (!user) {
		return null;
	}

	if (isCommercial) {
		return (
			<div
				className={`fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover overflow-auto px-10 ${
					user.email === "ps@1stle.com"
						? "bg-PSBackgroundImage"
						: user.email === "ess@1stle.com"
						? "bg-ESSBackgroundImage"
						: "bg-CommercialBackgroundImage"
				}`}
				onClick={() => (openSelectionMenu ? setOpenSelectionMenu(!openSelectionMenu) : "")}>
				<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
					<div className="flex items-center justify-center py-10">
						{user.email === "ps@1stle.com" ? (
							<img className="h-32" src={process.env.REACT_APP_PUBLIC_STORAGE_LOGO_URL} alt="PUBLIC STORAGE" />
						) : user.email === "ess@1stle.com" ? (
							<img className="h-32" src={process.env.REACT_APP_EXTRA_SPACE_STORAGE_LOGO_URL} alt="EXTRA SPACE STORAGE" />
						) : (
							""
						)}
					</div>
					<form onSubmit={(e) => searchProjects(e)} className="my-4 flex w-full justify-center items-center">
						<input
							type="text"
							className="border border-black p-2 w-full rounded-l-lg"
							placeholder="Search for installs"
							spellCheck={false}
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
						<button type="submit" className="border rounded-r-lg border-black bg-white p-3">
							<BsSearch />
						</button>
					</form>
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId="headers" direction="horizontal">
							{(provided) => (
								<ul className="headers" {...provided.droppableProps} ref={provided.innerRef}>
									<div className="w-full border border-black p-5 hidden md:flex justify-between bg-black text-white">
										{headers.map(
											(header, index) =>
												isHeaderSelected(header.id) && (
													<Draggable key={header.id} draggableId={header.id} index={index}>
														{(provided) => (
															<div
																className="md:basis-1/4"
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}>
																<div
																	className={`flex items-center ${header.id !== "project" ? "justify-center" : ""} ${
																		header.link ? "ml-10" : ""
																	}`}>
																	<p key={index}>{header.title}</p>
																	{header.id === "project" && (
																		<FontAwesomeIcon
																			icon={header.sorted ? faCaretUp : faCaretDown}
																			className="ml-2 cursor-pointer"
																			onClick={() => handleSortProjects(header.id)}
																		/>
																	)}
																	{header.id === "systemSize" && (
																		<FontAwesomeIcon
																			icon={header.sorted ? faCaretUp : faCaretDown}
																			className="ml-2 cursor-pointer"
																			onClick={() => handleSortProjects(header.id)}
																		/>
																	)}
																	{header.id === "systemOnDate" && (
																		<FontAwesomeIcon
																			icon={header.sorted ? faCaretUp : faCaretDown}
																			className="ml-2 cursor-pointer"
																			onClick={() => handleSortProjects(header.id)}
																		/>
																	)}
																</div>
															</div>
														)}
													</Draggable>
												)
										)}
										<div className="flex items-center">
											<HiEllipsisVertical size={25} className="cursor-pointer" onClick={() => setOpenSelectionMenu(!openSelectionMenu)} />
										</div>
									</div>
									{openSelectionMenu ? (
										<div className="flex justify-end" onClick={(e) => e.stopPropagation()}>
											<div id="selectionDropdown" className="w-48 bg-white rounded-lg absolute shadow z-50">
												<ul className="p-3 space-y-1 text-sm text-gray-700" aria-labelledby="selectionDropdown">
													<li>
														<div className="flex items-center p-2 rounded hover:bg-gray-100">
															<input
																id="contractStatus"
																type="checkbox"
																value="contractStatus"
																className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
																checked={contractStatusSelected}
																onChange={() => setContractStatusSelected(!contractStatusSelected)}
															/>
															<label htmlFor="contractStatus" className="w-full ms-2 text-sm font-medium text-gray-900 rounded">
																Contract Status
															</label>
														</div>
													</li>
													<li>
														<div className="flex items-center p-2 rounded hover:bg-gray-100">
															<input
																id="systemSize"
																type="checkbox"
																value="systemSize"
																className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
																checked={systemSizeSelected}
																onChange={() => setSystemSizeSelected(!systemSizeSelected)}
															/>
															<label htmlFor="systemSize" className="w-full ms-2 text-sm font-medium text-gray-900 rounded">
																System Size
															</label>
														</div>
													</li>
													<li>
														<div className="flex items-center p-2 rounded hover:bg-gray-100">
															<input
																id="systemOnDate"
																type="checkbox"
																value="systemOnDate"
																className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
																checked={systemOnDate}
																onChange={() => setSystemOnDateSelected(!systemOnDate)}
															/>
															<label htmlFor="systemOnDate" className="w-full ms-2 text-sm font-medium text-gray-900 rounded">
																System on Date
															</label>
														</div>
													</li>
													<li>
														<div className="flex items-center p-2 rounded hover:bg-gray-100">
															<input
																id="monitoring"
																type="checkbox"
																value="monitoring"
																className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
																checked={monitoringSelected}
																onChange={() => setMonitoringSelected(!monitoringSelected)}
															/>
															<label htmlFor="monitoring" className="w-full ms-2 text-sm font-medium text-gray-900 rounded">
																Monitoring
															</label>
														</div>
													</li>
													<li>
														<div className="flex items-center p-2 rounded hover:bg-gray-100">
															<input
																id="services"
																type="checkbox"
																value="services"
																className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
																checked={servicesSelected}
																onChange={() => setServicesSelected(!servicesSelected)}
															/>
															<label htmlFor="services" className="w-full ms-2 text-sm font-medium text-gray-900 rounded">
																Site Info
															</label>
														</div>
													</li>
													<li>
														<div className="flex items-center p-2 rounded hover:bg-gray-100">
															<input
																id="tickets"
																type="checkbox"
																value="tickets"
																className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
																checked={ticketsSelected}
																onChange={() => setTicketsSelected(!ticketsSelected)}
															/>
															<label htmlFor="tickets" className="w-full ms-2 text-sm font-medium text-gray-900 rounded">
																Tickets
															</label>
														</div>
													</li>
												</ul>
											</div>
										</div>
									) : null}
									{installation.length > 0 ? (
										installationData.map((value, index) => (
											<div
												key={index}
												className={`gap-3 border border-black p-3 flex flex-col md:flex-row justify-between items-center ${
													user.email === "ps@1stle.com"
														? index % 2 === 0
															? "bg-gray-300"
															: "bg-white"
														: user.email === "ess@1stle.com"
														? index % 2 === 0
															? "bg-lime-400"
															: "bg-white"
														: index % 2 === 0
														? "bg-yellow-200"
														: "bg-gray-100"
												}`}>
												{headers.map((header, columnIndex) => {
													return (
														isHeaderSelected(header.id) && (
															<div key={columnIndex} className={`md:basis-1/2 ${header.link ? "group" : ""}`}>
																<div className={`flex items-center ${header.id !== "project" ? "justify-center" : ""}`}>
																	{header.link ? (
																		header.id === "services" ? (
																			<button
																				className={`inline-flex items-center py-2 px-4 text-md font-medium text-center text-black rounded-full focus:ring-4 focus:ring-primary-200 cursor-pointer ${
																					user.email === "ps@1stle.com"
																						? "bg-orange-500 hover:bg-orange-600"
																						: user.email === "ess@1stle.com"
																						? "bg-green-500 hover:bg-green-600"
																						: "bg-yellow-400 hover:bg-yellow-500"
																				}`}
																				onClick={() =>
																					navigate("/installation/" + value.id, { state: { id: 1, name: value.id } })
																				}>
																				Site Info
																			</button>
																		) : header.id === "monitoring" ? (
																			<button
																				className={`inline-flex items-center py-2 px-4 text-md font-medium text-center text-black rounded-full focus:ring-4 focus:ring-primary-200 cursor-pointer ${
																					user.email === "ps@1stle.com"
																						? "bg-orange-500 hover:bg-orange-600"
																						: user.email === "ess@1stle.com"
																						? "bg-green-500 hover:bg-green-600"
																						: "bg-yellow-400 hover:bg-yellow-500"
																				}`}
																				onClick={() => navigate("/monitoring/" + value.id)}>
																				Monitor
																			</button>
																		) : value.x_x_studio_installation_helpdesk_ticket_count > 0 ? (
																			<button
																				className={`inline-flex items-center py-2 px-4 text-md font-medium text-center text-black rounded-full focus:ring-4 focus:ring-primary-200 cursor-pointer ${
																					user.email === "ps@1stle.com"
																						? "bg-orange-500 hover:bg-orange-600"
																						: user.email === "ess@1stle.com"
																						? "bg-green-500 hover:bg-green-600"
																						: "bg-yellow-400 hover:bg-yellow-500"
																				}`}
																				onClick={() => navigate("/tickets", { state: { id: 1, name: value.id } })}>
																				View Tickets
																			</button>
																		) : (
																			<button
																				className={`inline-flex items-center py-2 px-4 text-md font-medium text-center text-black rounded-full focus:ring-4 focus:ring-primary-200 cursor-pointer ${
																					user.email === "ps@1stle.com"
																						? "bg-orange-500 hover:bg-orange-600"
																						: user.email === "ess@1stle.com"
																						? "bg-green-500 hover:bg-green-600"
																						: "bg-yellow-400 hover:bg-yellow-500"
																				}`}
																				onClick={() =>
																					navigate("/submitticket/" + value.id, { state: { id: 1, name: value.id } })
																				}>
																				Create Ticket
																			</button>
																		)
																	) : header.field === "x_studio_contract_system_size_kw_1" ? (
																		value[header.field].toFixed(2)
																	) : header.field ? (
																		value[header.field]
																	) : null}
																</div>
															</div>
														)
													);
												})}
											</div>
										))
									) : isLoading ? (
										<div className="flex flex-col items-center justify-center w-full my-2">
											<LoadingCircle />
											<p className="text-white">Loading installs</p>
										</div>
									) : (
										<p className="text-white text-center text-xl m-4">No Results Found</p>
									)}
									<div className="bg-black bg-opacity-20 text-center py-10">
										<span className="text-lg text-white font-bold">
											Showing
											<span className="font-semibold text-white"> {globalPage * 10 + 1}</span> to
											<span className="font-semibold text-white">
												{" "}
												{10 * (globalPage + 1) < installation.length ? 10 * (globalPage + 1) : installation.length}
											</span>{" "}
											of
											<span className="font-semibold text-white"> {installation.length}</span> Entries
										</span>

										<nav aria-label="Installation Navigation">
											<ul className="inline-flex items-center -space-x-px h-16">
												{PaginationNav1Presentation(pageCount, searchQuery, globalPage, setGlobalPage)}
											</ul>
										</nav>
									</div>
									{provided.placeholder}
								</ul>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</div>
		);
	} else {
		return (
			<div className="bg-1stleBackgroundImage fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover h-screen w-screen overflow-auto">
				<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
					<div className="flex items-center justify-center">
						<div className="grid grid-cols-1 md:grid-cols-5 gap-10 bg-slate-50 shadow-md px-5 pt-6 pb-8 mt-10 mb-10 ml-10 mr-10 relative w-full md:w-[1200px] bg-opacity-95">
							<div className="flex items-center justify-center col-span-full">
								<span className="font-bold text-2xl md:text-5xl">{street}</span>
							</div>
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full lg:col-span-1 lg:row-span-3 flex flex-col">
								<div className="bg-blue-50 w-full p-1 mb-5">
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg text-center md:text-xl font-bold tracking-tight text-gray-700">
										Critical Stages
									</h1>
								</div>
								<ul className="flex flex-col justify-between items-around h-full">
									<li
										className={`drop-shadow ${
											resiInstallation && resiInstallation[0]["x_studio_agreement_date"] ? "text-green-500" : "text-gray-500"
										}`}>
										Contract Signed
									</li>
									<li
										className={`drop-shadow ${
											resiInstallation && resiInstallation[0]["x_studio_rev_one_complete_p"] ? "text-green-500" : "text-gray-500"
										}`}>
										Design
									</li>
									<li
										className={`drop-shadow ${
											resiInstallation && resiInstallation[0]["x_studio_pti_submitted_p"] ? "text-green-500" : "text-gray-500"
										}`}>
										Utility IC Submission
									</li>
									<li
										className={`drop-shadow ${
											resiInstallation && resiInstallation[0]["x_studio_pti_approved_p"] ? "text-green-500" : "text-gray-500"
										}`}>
										Utility IC Approval
									</li>
									<li
										className={`drop-shadow ${
											resiInstallation && resiInstallation[0]["x_studio_building_permit_submitted_p"] ? "text-green-500" : "text-gray-500"
										}`}>
										Permit Submitted
									</li>
									<li
										className={`drop-shadow ${
											resiInstallation && resiInstallation[0]["x_studio_building_permit_approved_date"]
												? "text-green-500"
												: "text-gray-500"
										}`}>
										Permit Approved
									</li>
									<li
										className={`drop-shadow ${
											resiInstallation && resiInstallation[0]["x_studio_scheduled_start_date_2"] ? "text-green-500" : "text-gray-500"
										}`}>
										Mobalization
									</li>
									<li
										className={`drop-shadow ${
											resiInstallation && resiInstallation[0]["x_studio_total_installation_completion_date"]
												? "text-green-500"
												: "text-gray-500"
										}`}>
										Substantial Completion
									</li>
									<li
										className={`drop-shadow ${
											resiInstallation && resiInstallation[0]["x_studio_fsobp_complete_date_p"] ? "text-green-500" : "text-gray-500"
										}`}>
										AHJ COA
									</li>
									<li
										className={`drop-shadow ${
											resiInstallation && resiInstallation[0]["x_studio_pto_approved_p"] ? "text-green-500" : "text-gray-500"
										}`}>
										Utility PTO
									</li>
									<li
										className={`drop-shadow ${
											resiInstallation && resiInstallation[0]["x_studio_system_turned_on_date_p"] ? "text-green-500" : "text-gray-500"
										}`}>
										System Turn On
									</li>
									<li
										className={`drop-shadow ${
											resiInstallation && resiInstallation[0]["x_top_created_sent"] ? "text-green-500" : "text-gray-500"
										}`}>
										Project Complete
									</li>
								</ul>
							</div>
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full lg:col-span-2">
								<div className="bg-blue-50 w-full p-1 mb-5">
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
										Customer Information
									</h1>
								</div>
								<div>
									<p className="mb-3 font-medium text-gray-700">{name ? name.split("-")[0] : ""}</p>
									<p className="mb-3 font-normal text-gray-700">Installation: {name ? name : ""}</p>
									<p className="mb-3 font-normal text-gray-700">Email: {user.email}</p>
									<p className="mb-3 font-normal text-gray-700">Phone: {phone}</p>
									<p className="mb-3 font-normal text-gray-700">Address: {street + " " + city + ", " + state + " " + zip}</p>
								</div>
							</div>
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full lg:col-span-2">
								<div className="bg-blue-50 w-full p-1 mb-5">
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
										Financing Details
									</h1>
								</div>
								<div>
									<p className="mb-3 font-normal text-gray-700">System Pricing</p>
									<p className="mb-3 font-normal text-gray-700">System Price: {systemPrice ? currencyFormat.format(systemPrice) : ""}</p>
								</div>
							</div>
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full lg:col-span-2">
								<div className="bg-blue-50 w-full p-1 mb-5">
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
										Timeline
									</h1>
								</div>
								<div>
									<p className="mb-3 font-normal text-gray-700">
										Contract Signed Date: {contractSignedDate ? dateFormat(contractSignedDate) : ""}
									</p>
									<p className="mb-3 font-normal text-gray-700">Installation Date: {installDate ? dateFormat(installDate) : ""}</p>
								</div>
							</div>
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full lg:col-span-2">
								<div className="bg-blue-50 w-full p-1 mb-5">
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
										System Overview
									</h1>
								</div>
								<div>
									<p className="mb-3 font-normal text-gray-700">System Size: {systemSize ? systemSize + "kW" : ""}</p>
								</div>
							</div>
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full lg:col-span-2">
								<div className="bg-blue-50 w-full p-1 mb-5">
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
										Documents
									</h1>
								</div>
								{pdfUrl && designCompletedDate ? (
									<div className="flex items-center justify-center col-span-full lg:col-span-2">
										<button
											className="inline-flex items-center py-2.5 px-4 text-md font-medium text-center text-white bg-blue-500 rounded focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-blue-600"
											onClick={() => setShowDisclaimer(true)}>
											View Design
										</button>
									</div>
								) : null}
							</div>
						</div>
					</div>
					{showDisclaimer ? (
						<div>
							<Disclaimer closeDisclaimer={closeDisclaimer} showDesign={showDesign} isCommercial={isCommercial} />
						</div>
					) : disclaimerAccepted ? (
						designLoading ? (
							<div>Loading</div>
						) : (
							<div className="hidden md:block">
								<ViewDesign
									api={api}
									token={token}
									isCommercial={isCommercial}
									sidebarOpen={sidebarOpen}
									pdfUrl={pdfUrl}
									setPdfUrl={setPdfUrl}
									parent={parent}
									designApprovalDate={designApprovalDate}
								/>
							</div>
						)
					) : null}
				</div>
			</div>
		);
	}
};
