export default function ExpectationsCalculator({ expectedTableData }) {
	const months = [
		"Jan (kWh)",
		"Feb (kWh)",
		"Mar (kWh)",
		"Apr (kWh)",
		"May (kWh)",
		"Jun (kWh)",
		"Jul (kWh)",
		"Aug (kWh)",
		"Sep (kWh)",
		"Oct (kWh)",
		"Nov (kWh)",
		"Dec (kWh)",
	];

	const groupedData = expectedTableData.reduce((acc, { year, month, value }) => {
		if (!acc[year]) acc[year] = Array(12).fill(0);
		acc[year][month - 1] = value;
		return acc;
	}, {});

	const generateRows = () => {
		return Object.entries(groupedData).map(([year, values]) => (
			<tr key={year} className="border-b">
				<td className="py-2 px-4 font-bold">{year}</td>
				{values.map((value, index) => (
					<td key={index} className="py-2 px-4">{Math.round(value)}</td>
				))}
			</tr>
		));
	};

	return (
		<div className="overflow-x-auto mb-4">
			<table className="min-w-full bg-white border border-gray-200">
				<thead>
					<tr className="bg-black text-white border-b">
					<th className="py-2 px-4 text-left">Year</th>
						{months.map((month, index) => (
							<th key={index} className="py-2 px-4 text-left">{month}</th>
						))}
					</tr>
				</thead>
				<tbody>{generateRows()}</tbody>
			</table>
		</div>
	);
}
