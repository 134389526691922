import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";
import LoadingCircle from "./Utility/LoadingCircle";
import { Disclaimer } from "./Design/Disclaimer";
import { ViewDesign } from "./Design/ViewDesign";

export const ViewInstallation = ({ api, sidebarOpen, token, isCommercial }) => {
	const params = useParams();
	const { user } = useAuth0();
	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [zip, setZip] = useState("");
	const [systemPrice, setSystemPrice] = useState("");
	const [contractSignedDate, setContractSignedDate] = useState("");
	const [installDate, setInstallDate] = useState("");
	const [systemSize, setSystemSize] = useState("");
	const [ptoDate, setPtoDate] = useState("");
	const [finalContractCost, setFinalContractCost] = useState("");
	const [installStatus, setInstallStatus] = useState("");
	const [meters, setMeters] = useState("");
	const [designCompletedDate, setDesignCompletedDate] = useState("");
	const [designApprovalDate, setDesignApprovalDate] = useState("");
	const [pdfUrl, setPdfUrl] = useState("");
	const [showDisclaimer, setShowDisclaimer] = useState(false);
	const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
	const [installation, setInstallation] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [designLoading, setDesignLoading] = useState(false);
	const [installationNotFound, setInstallationNotFound] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (api === "" || !token || !isCommercial || !designCompletedDate) {
			return;
		}
		try {
			setDesignLoading(true);
			fetch(api + "/design", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({
					id: params.installationId,
					disclaimerAccepted: disclaimerAccepted,
					parentName: name,
					isCommercial: isCommercial,
				}),
				mode: "cors",
			})
				.then((res) => {
					const contentType = res.headers.get("Content-Type");
					if (contentType.includes("application/pdf")) {
						return res.blob().then((blob) => {
							const url = URL.createObjectURL(blob);
							setPdfUrl(url);
						});
					} else if (contentType.includes("application/json")) {
						return res.json().then((data) => {
							setDesignApprovalDate(data.approvalDate);
						});
					}
				})
				.catch((err) => {})
				.finally(() => {
					setDesignLoading(false);
				});
		} catch (error) {}
	}, [api, token, isCommercial, designCompletedDate, params.installationId, disclaimerAccepted, name]);

	useEffect(() => {
		if (api === "" || !token) {
			return;
		}
		setIsLoading(true);
		fetch(api + "/installation/" + params.installationId, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({ userEmail: user.email }),
			mode: "cors",
		})
			.then((res) => {
				return res.json();
			})
			.then((res) => {
				if (res.msg) {
					if (res.msg === "installation not found") {
						setInstallationNotFound(true);
					}
				} else {
					setIsLoading(false);
					setName(res.name);
					setPhone(res.phone);
					setStreet(res.street);
					setCity(res.city);
					setState(res.state);
					setZip(res.zip);
					setSystemPrice(res.systemPrice);
					setContractSignedDate(res.contractSignedDate);
					setInstallDate(res.installDate);
					setSystemSize(res.systemSize);
					setPtoDate(res.ptoDate);
					setFinalContractCost(res.finalContractCost);
					setInstallStatus(res.installStatus);
					setMeters(res.meters);
					setDesignCompletedDate(res.designCompletedDate);
					setInstallation(res.installation);
				}
			})
			.catch((error) => {});
	}, [api, params.installationId, token, user.email]);

	const currencyFormat = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	const dateFormat = (inputDate) => {
		var date = new Date(inputDate);
		if (!isNaN(date.getTime())) {
			return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
		}
	};

	const showDesign = () => {
		setDisclaimerAccepted(true);
		setShowDisclaimer(false);
	};

	const closeDisclaimer = () => {
		setDisclaimerAccepted(false);
		setShowDisclaimer(false);
	};
	if (installationNotFound) {
		return (
			<>
				<div
					className={`${
						user.email === "ps@1stle.com"
							? "bg-PSBackgroundImage"
							: user.email === "ess@1stle.com"
							? "bg-ESSBackgroundImage"
							: isCommercial
							? "bg-CommercialBackgroundImage"
							: "bg-1stleBackgroundImage"
					} fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover`}></div>

				{/* <div className=" bg-slate-200/[.29] fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover"></div> */}
				<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
					<div className="m-10 p-4 bg-slate-50 bg-opacity-95">
						<div className="mt-4 flex flex-col items-center">
							<p className="text-2xl font-bold">404</p>
							<p className="text-xl mb-2">Installation not found</p>
						</div>
					</div>
				</div>
			</>
		);
	}

	if (isCommercial) {
		return (
			<div
				className={`fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover overflow-auto ${
					user.email === "ps@1stle.com"
						? "bg-PSBackgroundImage"
						: user.email === "ess@1stle.com"
						? "bg-ESSBackgroundImage"
						: "bg-CommercialBackgroundImage"
				}`}>
				<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
					<div className="flex items-center justify-center">
						<div className="grid grid-cols-1 md:grid-cols-5 gap-10 bg-slate-50 shadow-md px-5  pt-6 pb-8 mt-10 mb-10 ml-10 mr-10 relative w-full md:w-[1200px] bg-opacity-95">
							<div className="md:col-span-1 col-span-full">
								<div
									className={`absolute top-5 left-5 p-3 flex items-center justify-center gap-2 w-32 cursor-pointer ${
										user.email === "ps@1stle.com"
											? "bg-[#FFAC1C] hover:bg-orange-500"
											: user.email === "ess@1stle.com"
											? "bg-lime-400 hover:bg-lime-500"
											: "bg-[#FFEA00] hover:bg-yellow-400"
									}`}
									onClick={() => navigate("/installation")}>
									<IoArrowBackCircle className="text-2xl cursor-pointer" />
									<p className="cursor-pointer">Go back</p>
								</div>
							</div>
							<div className="flex items-center justify-center col-span-full mt-5">
								<span className="font-bold text-2xl md:text-5xl">{name}</span>
							</div>

							{installation ? (
								<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full lg:col-span-1 lg:row-span-2 flex flex-col">
									<div
										className={`w-full p-1 mb-5 ${
											user.email === "ps@1stle.com" ? "bg-[#FFAC1C]" : user.email === "ess@1stle.com" ? "bg-lime-400" : "bg-[#FFEA00]"
										}`}>
										<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700 text-center">
											Critical Stages
										</h1>
									</div>
									<ul className="flex flex-col justify-between items-between h-full">
										<li className={`drop-shadow ${installation[0]["x_studio_agreement_date"] ? "text-green-500" : "text-gray-500"}`}>
											Contract Signed
										</li>
										<li className={`drop-shadow ${installation[0]["x_studio_rev_one_complete_p"] ? "text-green-500" : "text-gray-500"}`}>
											Design
										</li>
										<li className={`drop-shadow ${installation[0]["x_studio_pti_submitted_p"] ? "text-green-500" : "text-gray-500"}`}>
											Utility IC Submission
										</li>
										<li className={`drop-shadow ${installation[0]["x_studio_pti_approved_p"] ? "text-green-500" : "text-gray-500"}`}>
											Utility IC Approval
										</li>
										<li
											className={`drop-shadow ${
												installation[0]["x_studio_building_permit_submitted_p"] ? "text-green-500" : "text-gray-500"
											}`}>
											Permit Submitted
										</li>
										<li
											className={`drop-shadow ${
												installation[0]["x_studio_building_permit_approved_date"] ? "text-green-500" : "text-gray-500"
											}`}>
											Permit Approved
										</li>
										<li
											className={`drop-shadow ${
												installation[0]["x_studio_scheduled_start_date_2"] ? "text-green-500" : "text-gray-500"
											}`}>
											Mobalization
										</li>
										<li
											className={`drop-shadow ${
												installation[0]["x_studio_total_installation_completion_date"] ? "text-green-500" : "text-gray-500"
											}`}>
											Substantial Completion
										</li>
										<li className={`drop-shadow ${installation[0]["x_studio_fsobp_complete_date_p"] ? "text-green-500" : "text-gray-500"}`}>
											AHJ COA
										</li>
										<li className={`drop-shadow ${installation[0]["x_studio_pto_approved_p"] ? "text-green-500" : "text-gray-500"}`}>
											Utility PTO
										</li>
										<li
											className={`drop-shadow ${
												installation[0]["x_studio_system_turned_on_date_p"] ? "text-green-500" : "text-gray-500"
											}`}>
											System Turn On
										</li>
										<li className={`drop-shadow ${installation[0]["x_top_created_sent"] ? "text-green-500" : "text-gray-500"}`}>
											Project Complete
										</li>
									</ul>
								</div>
							) : null}
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full lg:col-span-3 lg:col-start-2">
								<div
									className={`w-full p-1 mb-5 ${
										user.email === "ps@1stle.com" ? "bg-[#FFAC1C]" : user.email === "ess@1stle.com" ? "bg-lime-400" : "bg-[#FFEA00]"
									}`}>
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-black">
										Customer Information
									</h1>
								</div>
								{isLoading ? (
									<div className="flex items-center justify-center">
										<LoadingCircle />
									</div>
								) : (
									<div>
										<p className="mb-3 font-normal text-gray-700">
											Address: {street && city && state && zip ? street + ", " + city + ", " + state + " " + zip : ""}
										</p>
									</div>
								)}
							</div>
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full lg:col-span-3 lg:col-start-2">
								<div
									className={`w-full p-1 mb-5 ${
										user.email === "ps@1stle.com" ? "bg-[#FFAC1C]" : user.email === "ess@1stle.com" ? "bg-lime-400" : "bg-[#FFEA00]"
									}`}>
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-black">
										Site Info
									</h1>
								</div>
								{isLoading ? (
									<div className="flex items-center justify-center">
										<LoadingCircle />
									</div>
								) : (
									<div>
										<p className="mb-3 font-normal text-gray-700">PTO Date: {ptoDate}</p>
										<p className="mb-3 font-normal text-gray-700">
											Total System Cost: {systemPrice ? currencyFormat.format(systemPrice) : ""}
										</p>
										<p className="mb-3 font-normal text-gray-700">Contracted System Size: {systemSize + "kW"}</p>
										<p className="mb-3 font-normal text-gray-700">
											Contracted System Cost: {finalContractCost ? currencyFormat.format(finalContractCost) : ""}
										</p>
										<p className="mb-3 font-normal text-gray-700">Number of Meters: {meters ? meters : ""}</p>
										<p className="mb-3 font-normal text-gray-700">Install Status: {installStatus}</p>
									</div>
								)}
							</div>
							{/* 							
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full col-span-full lg:col-span-3 lg:col-start-2">
								<div
									className={`w-full p-1 mb-5 ${
										user.email === "ps@1stle.com" ? "bg-[#FFAC1C]" : user.email === "ess@1stle.com" ? "bg-lime-400" : "bg-[#FFEA00]"
									}`}>
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-black">
										Design
									</h1>
								</div>
								{designLoading ? (
									<div className="flex items-center justify-center">
										<LoadingCircle />
									</div>
								) : pdfUrl && designCompletedDate ? (
									<div className="flex items-center justify-center">
										<button
											className={`inline-flex items-center py-2.5 px-4 text-md font-medium text-center text-black rounded focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 ${
												user.email === "ps@1stle.com"
													? "bg-[#FFAC1C] hover:bg-orange-500"
													: user.email === "ess@1stle.com"
													? "bg-lime-400 hover:bg-lime-500"
													: "bg-[#FFEA00] hover:bg-yellow-400"
											}`}
											onClick={() => setShowDisclaimer(true)}
											type="submit">
											View Design
										</button>
									</div>
								) : null}
							</div> */}
						</div>
					</div>
					{/* {showDisclaimer ? (
						<div>
							<Disclaimer closeDisclaimer={closeDisclaimer} showDesign={showDesign} isCommercial={isCommercial} />
						</div>
					) : disclaimerAccepted ? (
						designLoading ? (
							<div>Loading...</div>
						) : (
							<div className="hidden md:block">
								<ViewDesign
									api={api}
									token={token}
									isCommercial={isCommercial}
									sidebarOpen={sidebarOpen}
									pdfUrl={pdfUrl}
									setPdfUrl={setPdfUrl}
									parent={params.installationId}
									name={name}
									designApprovalDate={designApprovalDate}
								/>
							</div>
						)
					) : null} */}
				</div>
			</div>
		);
	} else {
		return (
			<div className="bg-1stleBackgroundImage fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover overflow-auto">
				<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
					<div className="flex items-center justify-center">
						<div className="grid grid-cols-1 md:grid-cols-5 gap-10 bg-slate-50 shadow-md px-5 pt-6 pb-8 mt-10 mb-10 ml-10 mr-10 relative w-full md:w-[1200px] bg-opacity-95">
							<div className="flex items-center justify-center col-span-full">
								<span className="font-bold text-2xl md:text-5xl">{street}</span>
							</div>

							{installation ? (
								<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full lg:col-span-1 lg:row-span-3 flex flex-col">
									<div className="w-full p-1 mb-2 bg-blue-50">
										<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl text-center font-bold tracking-tight text-gray-700">
											Critical Stages
										</h1>
									</div>
									<ul className="flex flex-col justify-between h-full">
										<li className={`drop-shadow ${installation[0]["x_studio_agreement_date"] ? "text-green-500" : "text-gray-500"}`}>
											Contract Signed
										</li>
										<li className={`drop-shadow ${installation[0]["x_studio_rev_one_complete_p"] ? "text-green-500" : "text-gray-500"}`}>
											Design
										</li>
										<li className={`drop-shadow ${installation[0]["x_studio_pti_submitted_p"] ? "text-green-500" : "text-gray-500"}`}>
											Utility IC Submission
										</li>
										<li className={`drop-shadow ${installation[0]["x_studio_pti_approved_p"] ? "text-green-500" : "text-gray-500"}`}>
											Utility IC Approval
										</li>
										<li
											className={`drop-shadow ${
												installation[0]["x_studio_building_permit_submitted_p"] ? "text-green-500" : "text-gray-500"
											}`}>
											Permit Submitted
										</li>
										<li
											className={`drop-shadow ${
												installation[0]["x_studio_building_permit_approved_date"] ? "text-green-500" : "text-gray-500"
											}`}>
											Permit Approved
										</li>
										<li
											className={`drop-shadow ${
												installation[0]["x_studio_scheduled_start_date_2"] ? "text-green-500" : "text-gray-500"
											}`}>
											Mobalization
										</li>
										<li
											className={`drop-shadow ${
												installation[0]["x_studio_total_installation_completion_date"] ? "text-green-500" : "text-gray-500"
											}`}>
											Substantial Completion
										</li>
										<li className={`drop-shadow ${installation[0]["x_studio_fsobp_complete_date_p"] ? "text-green-500" : "text-gray-500"}`}>
											AHJ COA
										</li>
										<li className={`drop-shadow ${installation[0]["x_studio_pto_approved_p"] ? "text-green-500" : "text-gray-500"}`}>
											Utility PTO
										</li>
										<li
											className={`drop-shadow ${
												installation[0]["x_studio_system_turned_on_date_p"] ? "text-green-500" : "text-gray-500"
											}`}>
											System Turn On
										</li>
										<li className={`drop-shadow ${installation[0]["x_top_created_sent"] ? "text-green-500" : "text-gray-500"}`}>
											Project Complete
										</li>
									</ul>
								</div>
							) : null}
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full lg:col-span-2">
								<div className="bg-blue-50 w-full p-1 mb-5">
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
										Customer Information
									</h1>
								</div>
								<div>
									<p className="mb-3 font-medium text-gray-700">{name.split("-")[0]}</p>
									<p className="mb-3 font-normal text-gray-700">Installation: {name}</p>
									<p className="mb-3 font-normal text-gray-700">Email: {user.email}</p>
									<p className="mb-3 font-normal text-gray-700">Phone: {phone}</p>
									<p className="mb-3 font-normal text-gray-700">Address: {street + " " + city + ", " + state + " " + zip}</p>
								</div>
							</div>
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full lg:col-span-2">
								<div className="bg-blue-50 w-full p-1 mb-5">
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
										Financing Details
									</h1>
								</div>
								<div>
									<p className="mb-3 font-normal text-gray-700">System Pricing</p>
									<p className="mb-3 font-normal text-gray-700">System Price: {currencyFormat.format(systemPrice)}</p>
								</div>
							</div>
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full lg:col-span-2">
								<div className="bg-blue-50 w-full p-1 mb-5">
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
										Timeline
									</h1>
								</div>
								<div>
									<p className="mb-3 font-normal text-gray-700">Contract Signed Date: {dateFormat(contractSignedDate)}</p>
									<p className="mb-3 font-normal text-gray-700">Installation Date: {dateFormat(installDate)}</p>
								</div>
							</div>
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full lg:col-span-2">
								<div className="bg-blue-50 w-full p-1 mb-5">
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
										System Overview
									</h1>
								</div>
								<div>
									<p className="mb-3 font-normal text-gray-700">System Size: {systemSize + "kW"}</p>
								</div>
							</div>
							{/* <div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full lg:col-span-2">
								<div className="bg-blue-50 w-full p-1 mb-5">
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
										Documents
									</h1>
								</div>
								<div>
									<p className="mb-3 font-normal text-gray-700">Design</p>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		);
	}
};
